@mixin carouselButtonRound($size) {
  width: $size;
  height: $size;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 4;
  background: $carouselButtonBackground;
  border-radius: $carouselBorderRadius;
  box-shadow: $carouselButtonBoxShadow;
}
