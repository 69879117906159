@import '../../../bps/components/headline/headline.scss';

.headline {
  line-height: $htmlBlockTextLineHeight;
  color: $brandPrimaryColor;

  &--type_h3,
  &--type_h4 {
    font-family: $fontPrimaryFamily;
  }

  &--trocchi {
    font-family: $fontSecondaryFamily;
  }

  &--main-line,
  &--big,
  &--extra-big {
    color: $greenDarkColor;
  }

  &--sub-line {
    .button {
      font-family: $fontFamily;
    }
  }

  &--top-line {
    font-size: 20px !important;
    font-family: $fontFamily;
  }

  &--main-line {
    font-size: 24px !important;
    font-family: $fontHeadlineFamily;

    @media (min-width: $screen-tablet-portrait) {
      font-size: 36px !important;
    }

    @media (min-width: $screen-laptop) {
      font-size: 42px !important;
    }
  }

  &--no-margin {
    margin: 0;
  }

  &--center {
    h2,
    h3 {
      margin: auto;
      line-height: 1.2;
    }
  }

  &--left {
    text-align: left;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;

    h2,
    h3 {
      margin: 0;
    }
  }
}
