.headline {
  display: block;
  width: 100%;
  margin: 0;
  font-weight: $fontWeightRegular;
  font-family: $fontHeadlineFamily;

  &--type {
    &_teaser {
      display: flex;
      align-items: center;
      font-size: $fontSizeXXMedium;
      margin: 0 0 40px;

      &::before {
        content: '';
        margin-right: 20px;
        background-color: $borderDefaultColor;
        display: flex;
        height: 1px;
        flex-grow: 1;
      }

      &::after {
        content: '';
        margin-left: 20px;
        background-color: $borderDefaultColor;
        display: flex;
        height: 1px;
        flex-grow: 1;
      }
    }
  }

  &--type_h1,
  &.h1,
  h1 {
    @include fontSize($headlineH1FontSize);
    text-transform: $headlineH1TextTransform;
    font-weight: $headlineH1FontWeight;
  }

  &--type_h2,
  &.h2,
  h2 {
    @include fontSize($headlineH2FontSize);
    text-transform: $headlineH2TextTransform;
    font-weight: $headlineH2FontWeight;
  }

  &--type_h3,
  &.h3,
  h3 {
    @include fontSize($headlineH3FontSize);
    text-transform: $headlineH3TextTransform;
    font-weight: $headlineH3FontWeight;
  }

  &--type_h4,
  &.h4,
  h4 {
    @include fontSize($headlineH4FontSize);
    text-transform: $headlineH4TextTransform;
    font-weight: $headlineH4FontWeight;
  }

  h4,
  &.h4,
  h5,
  &.h5,
  h6,
  &.h6 {
    @include fontSize($headlineH4FontSize);
    text-transform: $headlineH4TextTransform;
    font-weight: $headlineH4FontWeight;
  }

  &--alt {
    font-family: $fontSecondaryFamily;
  }

  &--success {
    color: $successColor;
  }

  &--type_form {
    font-size: $formHeadlineFontSizeMobile;
    margin: $formHeadlineMarginMobile;
    font-weight: $formHeadlineFontWheight;
    color: $formHeadlineColor;

    @media (min-width: $screen-tablet-portrait) {
      font-size: $formHeadlineFontSizeDesktop;
      margin: $formHeadlineMarginDesktop;
    }
  }

  &--thin {
    font-weight: $fontWeightThin;
  }

  &--center {
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &--text {
    font-size: $fontSizeFont;
    font-weight: $fontWeightRegular;
    line-height: $htmlBlockTextLineHeight;
  }

  &--secondary-family {
    font-family: $fontSecondaryFamily;
  }

  &--top-line {
    @include fontSize($headlineTopLineFontSize);
    font-weight: $headlineTopLineFontWeight;
    letter-spacing: $headlineTopLineLetterSpacing;
    margin: $headlineTopLineMargin;
  }

  &--main-line {
    @include fontSize($headlineMainLineFontSize);
    font-weight: $headlineMainLineFontWeight;
  }

  &--sub-line {
    @include fontSize($headlineSubLineFontSize);
    font-weight: $headlineSubLineFontWeight;
    margin: $headlineSubLineMargin;
    max-width: $headlineSubLineMaxWidth;
    line-height: 1.2;
  }

  &--dark-shadow {
    background: linear-gradient(180deg, rgb(0 0 0 / .4), rgb(0 0 0 / 0)); // stylelint-disable-line plugin/no-unsupported-browser-features
  }

  &--big,
  &--extra-big {
    color: $brandSecondaryColor;
    font-weight: $headlineMainLineFontWeight !important;
    font-size: 35px !important;

    @media (min-width: $screen-tablet-portrait) {
      font-size: 40px !important;
    }

    @media (min-width: $screen-laptop) {
      font-size: 60px !important;
    }
  }

  &--extra-big {
    @media (min-width: $screen-laptop) {
      font-size: 70px !important;
    }
  }
}
