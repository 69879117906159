@import '../../utilities/scss/teasers/teaserLayout.scss';

.cinema-teaser {
  @include teaserContainer();

  .grid.teaser-in-column & {
    padding: 0;
  }

  &__headline {
    @include teaserHeadline();
    font-family: $fontSecondaryFamily;

    @media (min-width: $screen-laptop) {
      font-size: $headlineH1FontSize;
    }
  }

  &__link {
    @include teaserLink();
  }

  &__items-container {
    @include teaserItemsContainer();
    width: 100%;
  }

  &__products {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: stretch;

    &.products-list--type_box {
      justify-content: flex-start;
    }
  }

  &__section {
    body:not(.page-detail-view, .page-home) & {
      width: 100%;
      position: relative;
      padding: 0;
      min-height: 200px;

      @media (min-width: $screen-tablet-landscape) {
        padding: 0;
      }

      &:nth-child(even),
      &:nth-child(odd) {
        background: inherit;

        .read-more {
          &__btn {
            background: inherit;
          }
        }
      }
    }
  }

  &--smart-product-list {
    width: 100%;
  }

  &--mobile-full-with {
    .product-teaser-slider,
    .teasergroup_teaser--slide { // stylelint-disable-line
      width: calc(100% + 20px);
      margin: 0 -10px;

      @media (min-width: $screen-mobile-large) {
        width: calc(100% + 30px);
        margin: 0 -16px;
      }

      @media (min-width: $screen-tablet-portrait) {
        width: 100%;
        margin: 0;
      }
    }

    .carousel {
      &__page-info {
        display: none;
      }

      &__slide {
        @media (min-width: $screen-tablet-portrait) {
          display: flex;
          justify-content: center;
          flex-direction: column;
        }
      }

      &__button {
        position: absolute;
        top: 0;
        height: 100%;
        width: 50px;
        color: $carouselButtonTextColor;

        &--next {
          right: 5px;
          padding-right: 0;

          @media (min-width: $screen-tablet-portrait) {
            right: -15px;
          }

          @media (min-width: $screen-desktop) {
            right: -25px;
          }
        }

        &--prev {
          left: 5px;
          padding-left: 0;

          @media (min-width: $screen-tablet-portrait) {
            left: -15px;
          }

          @media (min-width: $screen-desktop) {
            left: -25px;
          }
        }
      }

      &__button-icon {
        position: absolute;
        background: transparent !important;
        box-shadow: none;
        width: 50px;
        height: 50px;
        padding: 5px;

        &::before {
          color: $blackColor !important;
          background: $whiteColor !important;
          box-shadow: 0 0 4px 1px rgb(0 0 0 / .1);
          border-radius: 50%;
          display: flex !important;
          justify-content: center;
          align-items: center;
          width: 40px;
          height: 40px;
        }
      }
    }
  }

  &--smart-product-cinema-interactive {
    margin: 20px 0;

    @media (min-width: $screen-desktop-xl) {
      margin: 40px 0;
    }

    .carousel--interactive-box {
      z-index: 100;

      .carousel__wrapper {
        width: 100%;
        margin: 0 0 -220px;
        overflow: hidden;
        padding: 0 0 220px;

        @media (min-width: $screen-tablet-portrait) {
          width: calc(100% + 28px);
          margin: 0 -14px -220px;
          padding: 0 14px 220px;
        }

        @media (min-width: $screen-desktop-xl) {
          width: calc(100% + 60px);
          margin: 0 -30px -220px;
          padding: 0 30px 220px;
        }
      }

      .carousel__button-icon {
        @media (min-width: $screen-tablet-portrait) {
          transform: translateY(calc(-50% - 110px));
        }
      }
    }

    .carousel__slide {
      min-height: 370px;

      @media (min-width: $screen-tablet-portrait) {
        min-height: 410px;
      }

      @media (min-width: $screen-tablet-landscape) {
        min-height: 510px;
      }
    }
  }

  &__below {
    order: 5;
  }

  &__placeholder {
    .carousel__container {
      transform: translate3d(21.5217px, 0, 0);

      @media (min-width: $screen-tablet-portrait) {
        transform: translate3d(0, 0, 0);
      }
    }

    .carousel__slide {
      margin-right: 10px;
      width: 277px;

      @media (min-width: $screen-tablet-portrait) {
        margin-right: 16px;
        width: 356px;
      }

      @media (min-width: $screen-tablet-landscape) {
        margin-right: 24px;
        width: 312px;
      }

      @media (min-width: $screen-desktop-xl) {
        margin-right: 40px;
        width: 279px;
      }

      &::after {
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: absolute;

        @include componentPreloader();
      }
    }
  }
}
